const vimeoLinks = document.querySelectorAll(
  'a[href*="player.vimeo.com/progressive_redirect"]',
);
const youtubeLinks = document.querySelectorAll('a[href*="youtube.com/watch"]');

function setMultipleAttributes(elmnt, attributesToSet) {
  const keys = Object.keys(attributesToSet);
  keys.forEach((key) => {
    elmnt.setAttribute(key, attributesToSet[key]);
  });
}

function createVid(type, attrs = null) {
  const newVideo = document.createElement(type);
  setMultipleAttributes(newVideo, attrs);
  newVideo.classList.add('c-lightbox__video');
  return newVideo;
}

function setHref(linkEl, needle = null, haystack = null) {
  const link = linkEl;

  // Replace Youtube 'watch' for 'embed'
  // Else set direct resource link
  if (needle !== null && haystack !== null) {
    link.dataset.href = link.href.replace(needle, haystack);
  } else {
    link.dataset.href = link.href;
  }

  // Empty the trigger link
  link.href = '';
}

function videoLightBox(vidType, vidProps) {
  const lbWrapper = document.createElement('div');
  lbWrapper.classList.add('c-lightbox');

  const lbClose = document.createElement('a');
  lbClose.innerHTML = 'Close';
  lbClose.classList.add('c-lightbox__close');
  lbClose.addEventListener('click', (f) => {
    f.preventDefault();
    lbClose.removeEventListener('click', () => {});
    document.body.removeChild(lbWrapper);
  });

  const lbVideo = createVid(vidType, vidProps);

  lbWrapper.appendChild(lbVideo);
  lbWrapper.appendChild(lbClose);

  document.body.appendChild(lbWrapper);
}

if (vimeoLinks) {
  for (let index = 0; index < vimeoLinks.length; index += 1) {
    setHref(vimeoLinks[index]);

    vimeoLinks[index].addEventListener('click', (e) => {
      e.preventDefault();
      videoLightBox('video', {
        autoplay: 'true',
        controls: 'true',
        preload: 'true',
        src: vimeoLinks[index].dataset.href,
      });
    });
  }
}

if (youtubeLinks) {
  for (let index = 0; index < youtubeLinks.length; index += 1) {
    setHref(youtubeLinks[index], 'watch?v=', 'embed/');

    youtubeLinks[index].addEventListener('click', (e) => {
      e.preventDefault();
      videoLightBox('iframe', {
        frameBorder: 0,
        src: `${youtubeLinks[index].dataset.href}?ecver=1&amp;autoplay=1&amp;iv_load_policy=3&amp;rel=0&amp;showinfo=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=white`,
      });
    });
  }
}
