const menuBtn = document.querySelector('.c-main-nav-trigger');
const menu = document.querySelector('.c-main-nav');

function scrollCheck() {
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    document.body.classList.add('s-scrolling');
  } else {
    document.body.classList.remove('s-scrolling');
  }
}

window.onscroll = () => {
  scrollCheck();
};

menuBtn.addEventListener('click', () => {
  // Lock window scroll when menu is open
  if (document.documentElement.style.overflow === 'hidden') {
    document.documentElement.style.overflow = 'scroll';
  } else {
    document.documentElement.style.overflow = 'hidden';
  }

  // Add class to body to nullify scroll CSS
  document.body.classList.toggle('s-active-menu');

  // Show/hide menu
  menu.classList.toggle('c-main-nav--active');
  menuBtn.classList.toggle('c-main-nav-trigger--active');
});
